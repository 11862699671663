"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverrideConsoleMethods = void 0;
const common_1 = require("@bugfender/common");
class OverrideConsoleMethods {
    constructor(commands, window) {
        this.commands = commands;
        this.window = window;
    }
    init(printToConsole) {
        const self = this;
        const methodToLevel = {
            log: common_1.LogLevel.Debug,
            trace: common_1.LogLevel.Trace,
            info: common_1.LogLevel.Info,
            warn: common_1.LogLevel.Warning,
            error: common_1.LogLevel.Error,
        };
        this.window.console = function (console) {
            return Object.assign(Object.assign({}, console), Object.fromEntries(Object.keys(methodToLevel).map((method) => ([
                method,
                function (...parameters) {
                    self.commands.addLogMessage(methodToLevel[method], parameters);
                    if (!printToConsole) {
                        console[method](...parameters);
                    }
                }
            ]))));
        }(this.window.console);
    }
}
exports.OverrideConsoleMethods = OverrideConsoleMethods;
