"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryIssuesTable = exports.IDBIssuesTable = void 0;
class IDBIssuesTable {
    constructor(table) {
        this.table = table;
    }
    add(item) {
        return this.table.add(item);
    }
    count(sessionUUID) {
        return this.table.countBy('sessionUUID', sessionUUID);
    }
    deleteById(id) {
        return this.table.deleteByIdx(id);
    }
    getBatch(sessionUUID, batchSize) {
        return this.table.getBatch(sessionUUID, batchSize);
    }
}
exports.IDBIssuesTable = IDBIssuesTable;
class InMemoryIssuesTable {
    constructor() {
        this.data = [];
        this.idCounter = 0;
    }
    getNextID() {
        return this.idCounter++;
    }
    add(item) {
        return __awaiter(this, void 0, void 0, function* () {
            const id = this.getNextID();
            this.data.push(Object.assign(Object.assign({}, item), { id }));
        });
    }
    count(sessionUUID) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.data.filter(d => d.sessionUUID === sessionUUID).length;
        });
    }
    deleteById(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const idx = this.data.findIndex(d => d.id === id);
            if (idx !== -1) {
                this.data.splice(idx, 1);
            }
        });
    }
    getBatch(sessionUUID, batchSize) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.data.filter(d => d.sessionUUID === sessionUUID).slice(0, batchSize);
        });
    }
}
exports.InMemoryIssuesTable = InMemoryIssuesTable;
