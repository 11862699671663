"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogLevel = exports.BugfenderClass = exports.Bugfender = void 0;
const common_1 = require("@bugfender/common");
Object.defineProperty(exports, "LogLevel", { enumerable: true, get: function () { return common_1.LogLevel; } });
const bugfender_1 = require("./bugfender");
Object.defineProperty(exports, "BugfenderClass", { enumerable: true, get: function () { return bugfender_1.Bugfender; } });
const pre_init_provider_1 = require("./providers/pre-init.provider");
const preInitProvider = new pre_init_provider_1.PreInitProvider(window);
const Bugfender = new bugfender_1.Bugfender(preInitProvider, SDK_USER_AGENT, VERSION);
exports.Bugfender = Bugfender;
