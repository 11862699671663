"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Timer = void 0;
class Timer {
    constructor(getNow = () => new Date()) {
        this.getNow = getNow;
        this.lastAbsoluteTime = 0;
    }
    getTime() {
        const now = this.getNow();
        let absoluteTime = now.getTime();
        if (absoluteTime <= this.lastAbsoluteTime) {
            absoluteTime = this.lastAbsoluteTime + 1;
        }
        this.lastAbsoluteTime = absoluteTime;
        return {
            iso: now.toISOString(),
            epoch: absoluteTime,
        };
    }
}
exports.Timer = Timer;
