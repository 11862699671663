"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoopLogger = exports.ConsoleLogger = void 0;
class ConsoleLogger {
    constructor(console, tag) {
        this.console = console;
        this.tag = tag;
    }
    error(msg, data) {
        const tag = this.tag ? `[${this.tag}] ` : '';
        msg = `[BF-SDK]${tag}${msg}`;
        if (data) {
            this.console.log(msg, data);
        }
        else {
            this.console.log(msg);
        }
    }
    getTagged(tag) {
        return new ConsoleLogger(this.console, tag);
    }
    log(msg, data) {
        const tag = this.tag ? `[${this.tag}] ` : '';
        msg = `[BF-SDK]${tag}${msg}`;
        if (data) {
            this.console.log(msg, data);
        }
        else {
            this.console.log(msg);
        }
    }
}
exports.ConsoleLogger = ConsoleLogger;
class NoopLogger {
    error() {
        return;
    }
    getTagged() {
        return new NoopLogger();
    }
    log() {
        return;
    }
}
exports.NoopLogger = NoopLogger;
