"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageProcessor = void 0;
class StorageProcessor {
    constructor(api, channel, connection, logger, device, deviceKeysTable, issuesTable, logsTable, options, sessionFactory, sessionsTable, storageGuard) {
        this.api = api;
        this.channel = channel;
        this.connection = connection;
        this.logger = logger;
        this.device = device;
        this.deviceKeysTable = deviceKeysTable;
        this.issuesTable = issuesTable;
        this.logsTable = logsTable;
        this.options = options;
        this.sessionFactory = sessionFactory;
        this.sessionsTable = sessionsTable;
        this.storageGuard = storageGuard;
        this.BATCH_SIZE = 500;
        this.runIntervalMs = 15000;
        this.forceSend = false;
        this.isRunning = false;
        setInterval(this.run.bind(this), this.runIntervalMs);
        this.channel.onHasBecomeLeader().then(() => {
            this.run();
        });
        this.channel.onForceSend(() => {
            this.forceSend = true;
        });
    }
    cleanupSessions(sessions) {
        return __awaiter(this, void 0, void 0, function* () {
            for (const session of sessions) {
                const hasActiveTab = yield this.channel.isSessionActive(session.uuid);
                const hasData = yield this.hasData(session);
                const shouldDelete = !hasActiveTab && !hasData;
                if (shouldDelete) {
                    try {
                        yield this.sessionsTable.delete(session.uuid);
                    }
                    catch (err) {
                    }
                }
            }
        });
    }
    hasData(session) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const [logsCount, issuesCount, deviceKeysCount] = yield Promise.all([
                    this.logsTable.count(session.uuid),
                    this.issuesTable.count(session.uuid),
                    this.deviceKeysTable.count(session.uuid),
                ]);
                return logsCount > 0 || issuesCount > 0 || deviceKeysCount > 0;
            }
            catch (err) {
                return true;
            }
        });
    }
    registerSession(sessionIDB) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!sessionIDB.id) {
                const session = this.sessionFactory.create(sessionIDB.uuid, this.options);
                const { id } = yield this.api.sendSession(session);
                sessionIDB.id = id;
                this.sessionsTable.update(sessionIDB.uuid, { id });
            }
            return sessionIDB;
        });
    }
    canRun() {
        return (this.connection.isOnline() &&
            this.channel.isLeader() &&
            !this.isRunning);
    }
    run() {
        return __awaiter(this, void 0, void 0, function* () {
            const canRun = this.canRun();
            this.logger.log('Processing...', {
                forceSend: this.forceSend,
                isRunning: this.isRunning,
                isLeader: this.channel.isLeader(),
                isOnline: this.connection.isOnline(),
                canRun,
            });
            if (!canRun) {
                this.logger.log('Aborted.');
                return;
            }
            this.isRunning = true;
            const isForceSend = this.forceSend;
            const sessions = yield this.sessionsTable.getAll();
            const apiPromises = [];
            const shouldProcess = this.storageGuard.canProcess() || this.forceSend;
            let hadIssues = false;
            this.logger.log(`Should process device: ${shouldProcess ? 'Yes' : 'No'}`);
            this.logger.log(`${sessions.length} session/s found`);
            sessions.sort((a, b) => Number(!!b.id) - Number(!!a.id));
            for (const partialSession of sessions) {
                const session = yield this.registerSession(partialSession);
                const sessionHasIssues = yield this.sendIssues(session);
                hadIssues = hadIssues || sessionHasIssues;
                if (sessionHasIssues || shouldProcess) {
                    apiPromises.push(this.sendLogs(session));
                    this.logger.log(`Session processed. ID: ${session.id}`, { sessionHasIssues });
                }
                else {
                    this.logger.log(`Session skipped. ID: ${session.id}`);
                }
            }
            if (hadIssues || shouldProcess) {
                apiPromises.push(this.sendDeviceData());
            }
            yield Promise.all(apiPromises);
            yield this.cleanupSessions(sessions);
            this.isRunning = false;
            if (isForceSend) {
                this.forceSend = false;
            }
            this.logger.log('Done');
        });
    }
    sendDeviceData() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const deviceKeyChanges = {};
                const deviceKeys = yield this.deviceKeysTable.getAll();
                deviceKeys.forEach(entry => {
                    deviceKeyChanges[entry.data.key] = entry.data.value;
                });
                if (deviceKeys.length) {
                    yield Promise.all(Object.entries(deviceKeyChanges)
                        .map(([key, value]) => this.api.setDeviceKey(this.device.getUDID(), key, value)));
                    yield this.deviceKeysTable.deleteBatch(deviceKeys.length);
                }
            }
            catch (err) {
                this.logger.error('sendDeviceData error', err);
            }
        });
    }
    sendIssues(session) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const entries = yield this.issuesTable.getBatch(session.uuid, this.BATCH_SIZE);
                for (const entry of entries) {
                    const issue = entry.data;
                    const issueURL = `${this.options.baseURL}/intent/${this.options.appID}/issue/${issue.uuid}`;
                    yield this.api.sendIssue(issue.uuid, issueURL, issue.title, issue.text, issue.type, session.id);
                    yield this.issuesTable.deleteById(entry.id);
                }
                return entries.length > 0;
            }
            catch (err) {
                this.logger.error('sendIssues error', err);
                return false;
            }
        });
    }
    sendLogs(session) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const entries = yield this.logsTable.getBatch(session.uuid, this.BATCH_SIZE);
                const logs = entries.map(entry => entry.data);
                if (logs.length) {
                    yield this.api.sendLogs(session.id, logs);
                    yield this.logsTable.deleteBatch(session.uuid, logs.length);
                }
            }
            catch (err) {
                this.logger.error('sendLogs error', err);
            }
        });
    }
}
exports.StorageProcessor = StorageProcessor;
