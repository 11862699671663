"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainThreadStorageWriterHandler = void 0;
class MainThreadStorageWriterHandler {
    constructor(storageWriter) {
        this.storageWriter = storageWriter;
    }
    postMessage(msg) {
        this.storageWriter.handleMessage(msg);
    }
}
exports.MainThreadStorageWriterHandler = MainThreadStorageWriterHandler;
