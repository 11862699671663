"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Table = void 0;
class Table {
    constructor(dbPromise, store) {
        this.dbPromise = dbPromise;
        this.store = store;
    }
    add(data) {
        return __awaiter(this, void 0, void 0, function* () {
            yield (yield this.dbPromise).add(this.store, data);
        });
    }
    clear() {
        return __awaiter(this, void 0, void 0, function* () {
            yield (yield this.dbPromise).clear(this.store);
        });
    }
    countBy(key, value) {
        return __awaiter(this, void 0, void 0, function* () {
            const tx = (yield this.dbPromise).transaction(this.store, 'readonly');
            let cursor;
            let count = 0;
            if (key !== tx.store.keyPath) {
                const index = tx.store.index(key);
                const range = IDBKeyRange.only(value);
                cursor = yield index.openCursor(range);
            }
            else {
                cursor = yield tx.store.openCursor(IDBKeyRange.only(value));
            }
            while (cursor) {
                count++;
                cursor = yield cursor.continue();
            }
            return count;
        });
    }
    deleteBatch(offset, limit, where) {
        return __awaiter(this, void 0, void 0, function* () {
            const tx = (yield this.dbPromise).transaction(this.store, 'readwrite');
            let cursor;
            let count = 0;
            if (where) {
                if (where[0] !== tx.store.keyPath) {
                    const index = tx.store.index(where[0]);
                    const range = IDBKeyRange.only(where[1]);
                    cursor = yield index.openCursor(range);
                }
                else {
                    cursor = yield tx.store.openCursor(IDBKeyRange.only(where[1]));
                }
            }
            else {
                cursor = yield tx.store.openCursor();
            }
            while (cursor) {
                if (count >= offset) {
                    cursor.delete();
                }
                count++;
                if (count === (offset + limit)) {
                    break;
                }
                else {
                    cursor = yield cursor.continue();
                }
            }
        });
    }
    deleteByIdx(id) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.dbPromise).delete(this.store, id);
        });
    }
    getAll() {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.dbPromise).getAll(this.store);
        });
    }
    getBatch(sessionUUID, batchSize) {
        return __awaiter(this, void 0, void 0, function* () {
            const tx = (yield this.dbPromise).transaction(this.store, 'readonly');
            const index = tx.store.index('sessionUUID');
            const range = IDBKeyRange.only(sessionUUID);
            let cursor = yield index.openCursor(range);
            const result = [];
            let count = 0;
            while (cursor) {
                result.push(cursor.value);
                count++;
                if (count === batchSize) {
                    break;
                }
                else {
                    cursor = yield cursor.continue();
                }
            }
            return result;
        });
    }
    patchByIdx(id, data) {
        return __awaiter(this, void 0, void 0, function* () {
            const tx = (yield this.dbPromise).transaction(this.store, 'readwrite');
            const entry = yield tx.store.get(id);
            yield tx.store.put(Object.assign(Object.assign({}, entry), data));
        });
    }
}
exports.Table = Table;
