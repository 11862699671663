"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrentSession = void 0;
const storage_keys_1 = require("./storage-keys");
class CurrentSession {
    constructor(device, logger, sessionStorage, sessionsTable, uuidFactory) {
        this.device = device;
        this.logger = logger;
        this.sessionStorage = sessionStorage;
        this.sessionsTable = sessionsTable;
        this.uuidFactory = uuidFactory;
        this.reset();
    }
    getUUID() {
        return this.uuid;
    }
    initSession() {
        const deviceUDID = this.device.getUDID();
        this.logger.log('Init session');
        this.uuid = this.uuidFactory.create();
        this.sessionStorage.setItem(storage_keys_1.SessionStorageKey.SessionUUID, this.uuid);
        this.sessionStorage.setItem(storage_keys_1.SessionStorageKey.SessionUDID, deviceUDID);
    }
    reset() {
        const uuid = this.sessionStorage.getItem(storage_keys_1.SessionStorageKey.SessionUUID);
        if (uuid === null) {
            this.logger.log('Session not available');
            this.initSession();
        }
        else {
            this.uuid = uuid;
            const sessionDeviceUDID = this.sessionStorage.getItem(storage_keys_1.SessionStorageKey.SessionUDID);
            const hasDeviceUDIDChanged = sessionDeviceUDID !== this.device.getUDID();
            if (hasDeviceUDIDChanged) {
                this.logger.log('Session Device UDID changed');
                this.initSession();
            }
            else {
                this.logger.log(`Session UUID: ${this.uuid}`);
            }
        }
        this.sessionsTable.addIfMissing({
            uuid: this.uuid,
            udid: this.device.getUDID(),
        });
    }
}
exports.CurrentSession = CurrentSession;
