"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryStorage = void 0;
class InMemoryStorage {
    constructor() {
        this.inMemoryStorage = {};
    }
    clear() {
        this.inMemoryStorage = {};
    }
    getItem(key) {
        if (Object.prototype.hasOwnProperty.call(this.inMemoryStorage, key)) {
            return this.inMemoryStorage[key];
        }
        return null;
    }
    key(index) {
        var _a;
        return (_a = Object.keys(this.inMemoryStorage)[index]) !== null && _a !== void 0 ? _a : null;
    }
    removeItem(key) {
        delete this.inMemoryStorage[key];
    }
    setItem(key, value) {
        this.inMemoryStorage[key] = String(value);
    }
    get length() {
        return Object.keys(this.inMemoryStorage).length;
    }
}
exports.InMemoryStorage = InMemoryStorage;
