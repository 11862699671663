"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Connection = void 0;
class Connection {
    constructor(window) {
        this.window = window;
    }
    isOnline() {
        return this.window.navigator.onLine;
    }
}
exports.Connection = Connection;
