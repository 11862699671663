"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpClient = void 0;
class HttpClient {
    constructor(apiURL, appKey, sdkUserAgent) {
        this.apiURL = apiURL;
        this.appKey = appKey;
        this.sdkUserAgent = sdkUserAgent;
    }
    post(endpoint, payload) {
        const http = new XMLHttpRequest();
        http.open('POST', this.apiURL + endpoint);
        http.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        http.setRequestHeader('X-User-Agent', this.sdkUserAgent);
        http.setRequestHeader('X-App-Token', this.appKey);
        return new Promise((resolve, reject) => {
            http.onreadystatechange = () => {
                if (http.readyState !== 4) {
                    return;
                }
                if (http.status !== 200 && http.status !== 304) {
                    reject();
                }
                else {
                    resolve(JSON.parse(http.responseText));
                }
            };
            http.send(JSON.stringify(payload));
        });
    }
}
exports.HttpClient = HttpClient;
