"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Device = void 0;
const storage_keys_1 = require("./storage-keys");
class Device {
    constructor(browser, localStorage, sdkVersion, uuidFactory) {
        this.browser = browser;
        this.localStorage = localStorage;
        this.sdkVersion = sdkVersion;
        this.uuidFactory = uuidFactory;
    }
    getDeviceData() {
        let data = this.localStorage.getItem(storage_keys_1.LocalStorageKey.DeviceData);
        if (data === null) {
            data = '{}';
        }
        return JSON.parse(data);
    }
    getInfo(options) {
        return {
            udid: this.getUDID(),
            name: options.deviceName,
            device_type: '',
            firebase_test_lab: false,
            key_values: this.getDeviceData(),
            language: this.browser.getLanguage(),
            os_version: this.browser.getOSVersion(),
            sdk_type: 'web',
            sdk_version: this.sdkVersion.toString(),
            build: options.build,
            version: options.version,
        };
    }
    getUDID() {
        let udid = this.localStorage.getItem(storage_keys_1.LocalStorageKey.DeviceUDID);
        if (udid === null) {
            udid = this.uuidFactory.create();
            this.localStorage.setItem(storage_keys_1.LocalStorageKey.DeviceUDID, udid);
        }
        return udid;
    }
    setDeviceKey(key, value) {
        const data = this.getDeviceData();
        if (value === null) {
            delete data[key];
        }
        else {
            data[key] = value;
        }
        this.localStorage.setItem(storage_keys_1.LocalStorageKey.DeviceData, JSON.stringify(data));
    }
}
exports.Device = Device;
